<template>
    <div>
        <el-collapse>
            <el-collapse-item>
                <template slot="title">
                    <i class="header-icon el-icon-s-operation"></i>筛选
                    <span class="totalwrp">总条数:{{ total }}</span>
                </template>
                <div class="dataFilterWrap">
                    <el-row :gutter="8">
                        <el-col :span="4">
                            <el-button type="primary" @click="beforeAdd">添加</el-button>
                        </el-col>
                        <el-col :span="8">
                            <el-input type="text" v-model="keyword" placeholder="输入产品名关键字"></el-input>
                            <el-button type="success" round icon="el-icon-search" @click="searchKeyWord">筛选</el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-collapse-item>
        </el-collapse>
        <el-table :data="ProductList" stripe fit tooltip-effect="dark">
            <el-table-column label="序号" width="100">
                <template slot-scope="scope">{{ scope.$index }}</template>
            </el-table-column>
            <el-table-column label="产品名称[险种]" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.Policy_Name }}</template>
            </el-table-column>
            <el-table-column label="年龄" width="120" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.Policy_AgeMin == 0 ? '不限' : scope.row.Policy_AgeMin }} - {{ scope.row.Policy_AgeMax == 0 ? '不限' : scope.row.Policy_AgeMax }}</template>
            </el-table-column>
            <el-table-column label="延误" width="70" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.Policy_Delay == 1 ? '含' : '不含' }}</template>
            </el-table-column>
            <el-table-column label="按天计费" width="70" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.Policy_IsEvery == 1 ? '是' : '否' }}</template>
            </el-table-column>
            <el-table-column label="产品分类" width="120" show-overflow-tooltip>
                <template slot-scope="scope">{{ ProductType(scope.row.Policy_Quality) }}</template>
            </el-table-column>
            <el-table-column label="计费标准" width="220">
                <template slot-scope="scope">
                    {{ scope.row.Policy_Money + "元/(" + scope.row.Policy_DayMin + "至" + scope.row.Policy_DayMax + "天)"
                    }}</template>
            </el-table-column>
            <el-table-column label="添加时间" width="220">
                <template slot-scope="scope">{{ scope.row.Policy_Create }}</template>
            </el-table-column>
            <el-table-column label="更新时间" width="220">
                <template slot-scope="scope">{{ scope.row.Policy_UpdateTime }}</template>
            </el-table-column>
            <el-table-column label="操作" width="260" fixed="right">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" plain @click="edtPolicyAct(scope.row)">修改</el-button>
                    <el-popconfirm confirm-button-text="好的" cancel-button-text="再考虑" icon="el-icon-info" icon-color="red"
                        title="确定要删除吗？" @confirm="sendDelete(scope.row)">
                        <el-button type="danger" size="mini" plain slot="reference">删 除</el-button>
                    </el-popconfirm>
                    <!-- <el-button type="warning" size="mini" plain>冻结</el-button> -->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next, jumper" :total="total" @current-change="setPageIndx">
        </el-pagination>

        <el-dialog :visible.sync="addPolicy" title="添加产品" width="77%" modal :close-on-click-modal="false">
            <div class="details-wrp">
                <el-row class="policyWrp">
                    <el-col :span="24">
                        <label>产品名称</label>
                        <el-input type="text" v-model="current.Policy_Name"></el-input>
                    </el-col>
                    <el-col :span="24">
                        <label>旅游期限(0为不限制)</label>
                        <el-row>
                            <el-col :span="12">
                                <el-input type="number" v-model="current.Policy_DayMin" placeholder="天数"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <el-input type="number" v-model="current.Policy_DayMax" placeholder="天数"></el-input>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="24">
                        <label>年龄范围(0为不限制)</label>
                        <el-row>
                            <el-col :span="12">
                                <el-input type="number" v-model="current.Policy_AgeMin" placeholder="最小年龄"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <el-input type="number" v-model="current.Policy_AgeMax" placeholder="最大年龄"></el-input>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="12">
                        <label>保费</label>
                        <el-input type="number" v-model="current.Policy_Money" placeholder="价格数"></el-input>
                    </el-col>
                    <el-col :span="12">
                        <label>是否每天</label>
                        <el-select v-model="current.Policy_IsEvery" placeholder="请选择">
                            <el-option :value="0" label="否"></el-option>
                            <el-option :value="1" label="是"></el-option>

                        </el-select>
                    </el-col>
                    <el-col :span="12"><label>产品类型</label>
                        <el-select v-model="current.Policy_Quality" placeholder="请选择">
                            <el-option :value="1" label="境内（普通）"></el-option>
                            <el-option :value="2" label="境外（普通）"></el-option>
                            <el-option :value="3" label="境内（高端）"></el-option>
                            <el-option :value="4" label="境外（高端）"></el-option>
                            <el-option :value="5" label="境内（普通含延误险）"></el-option>
                            <el-option :value="6" label="境外（普通含延误险）"></el-option>
                            <el-option :value="7" label="境内（高端含延误险）"></el-option>
                            <el-option :value="8" label="境外（高端含延误险）"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="12"><label>险种分类</label>
                        <el-select v-model="current.Policy_Type" placeholder="请选择">
                            <el-option label="旅游险" :value="0"></el-option>
                            <el-option label="航意险" :value="1"></el-option>
                            <el-option label="职工疗休养险" :value="2"></el-option>
                            <el-option label="研学险" :value="3"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="12"><label>延误险</label>
                        <el-select v-model="current.Policy_Delay" placeholder="请选择">
                            <el-option :value="0" label="不含"></el-option>
                            <el-option :value="1" label="含"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="12"><label>是否隐藏价格</label>
                        <el-select v-model="current.Policy_HidePrice" placeholder="请选择">
                            <el-option :value="0" label="显示"></el-option>
                            <el-option :value="1" label="隐藏"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="24"><label>产品特约</label>
                        <el-input type="textarea" :rows="3" v-model="current.Policy_Term"></el-input>
                    </el-col>
                    <el-col :span="24"><label>产品描述</label>
                        <el-input type="textarea" :rows="3" v-model="current.Policy_Brief"></el-input>
                    </el-col>

                </el-row>
                <div class="btm-btns">
                    <el-button plain round type="default" @click="cancelAdd">取消</el-button>
                    <el-button plain round type="primary" @click="sureAdd">确定</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="edtPolicy" title="修改产品" width="77%" modal :close-on-click-modal="false">
            <div class="details-wrp">
                <el-row class="policyWrp">
                    <el-col :span="24">
                        <label>产品名称</label>
                        <el-input type="text" v-model="current.Policy_Name"></el-input>
                    </el-col>
                    <el-col :span="24">
                        <label>旅游期限(0为不限制)</label>
                        <el-row>
                            <el-col :span="12">
                                <el-input type="number" v-model="current.Policy_DayMin" placeholder="天数"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <el-input type="number" v-model="current.Policy_DayMax" placeholder="天数"></el-input>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="24">
                        <label>年龄范围(0为不限制)</label>
                        <el-row>
                            <el-col :span="12">
                                <el-input type="number" v-model="current.Policy_AgeMin" placeholder="最小年龄"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <el-input type="number" v-model="current.Policy_AgeMax" placeholder="最大年龄"></el-input>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="12">
                        <label>保费</label>
                        <el-input type="number" v-model="current.Policy_Money" placeholder="价格数"></el-input>
                    </el-col>
                    <el-col :span="12">
                        <label>是否每天</label>
                        <el-select v-model="current.Policy_IsEvery" placeholder="请选择">
                            <el-option :value="0" label="否"></el-option>
                            <el-option :value="1" label="是"></el-option>

                        </el-select>
                    </el-col>
                    <el-col :span="12"><label>产品类型</label>
                        <el-select v-model="current.Policy_Quality" placeholder="请选择">
                            <el-option :value="1" label="境内（普通）"></el-option>
                            <el-option :value="2" label="境外（普通）"></el-option>
                            <el-option :value="3" label="境内（高端）"></el-option>
                            <el-option :value="4" label="境外（高端）"></el-option>
                            <el-option :value="5" label="境内（普通含延误险）"></el-option>
                            <el-option :value="6" label="境外（普通含延误险）"></el-option>
                            <el-option :value="7" label="境内（高端含延误险）"></el-option>
                            <el-option :value="8" label="境外（高端含延误险）"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="12"><label>险种分类</label>
                        <el-select v-model="current.Policy_Type" placeholder="请选择">
                            <el-option label="旅游险" :value="0"></el-option>
                            <el-option label="航意险" :value="1"></el-option>
                            <el-option label="职工疗休养险" :value="2"></el-option>
                            <el-option label="研学险" :value="3"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="12"><label>延误险</label>
                        <el-select v-model="current.Policy_Delay" placeholder="请选择">
                            <el-option :value="0" label="不含"></el-option>
                            <el-option :value="1" label="含"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="12"><label>是否隐藏价格</label>
                        <el-select v-model="current.Policy_HidePrice" placeholder="请选择">
                            <el-option :value="0" label="显示"></el-option>
                            <el-option :value="1" label="隐藏"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="24"><label>产品特约</label>
                        <el-input type="textarea" :rows="3" v-model="current.Policy_Term"></el-input>
                    </el-col>
                    <el-col :span="24"><label>产品描述</label>
                        <el-input type="textarea" :rows="3" v-model="current.Policy_Brief"></el-input>
                    </el-col>

                </el-row>
                <div class="btm-btns">
                    <el-button plain round type="default" @click="cancelEdt">取消</el-button>
                    <el-button plain round type="primary" @click="sureEdt">确定</el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            ProductList: [],
            current: {
                Policy_Name: "",
                Policy_DayMin: 0,
                Policy_DayMax: 0,
                Policy_AgeMin: 0,
                Policy_AgeMax: 0,
                Policy_Money: 0,
                Policy_IsEvery: "",
                Policy_Quality: "",
                Policy_Type: "",
                Policy_Brief: "",
                Policy_Term: "",
                Policy_Delay: 0,
                Policy_HidePrice:0
            },
            addPolicy: false,
            edtPolicy: false,
            keyword: "",
            pageindex: 1,
            pagesize: 10,
            total: 0
        }
    },
    mounted() {
        this.pullList()
    },
    methods: {
        cancelEdt() {
            this.edtPolicy = false
        },
        cancelAdd() {
            this.addPolicy = false
        },
        edtPolicyAct(itm) {
            this.current = { ...itm }
            this.edtPolicy = true
        },
        sureEdt() {
            console.log("current edt:", this.current)
            this.ipost(
                "/AdminTravel/PolicyTravel.ashx?type=update", {
                model: JSON.stringify(this.current)
            },
                (res) => {
                    console.log("修改成功 res:", res)
                    this.edtPolicy = false
                    this.pullList()
                }
            );
        },
        beforeAdd() {
            this.resetCurrent()
            this.addPolicy = true
        },
        resetCurrent() {
            this.current.Policy_Name = "";
            this.current.Policy_DayMin = 0;
            this.current.Policy_DayMax = 0;
            this.current.Policy_AgeMin = 0;
            this.current.Policy_AgeMax = 0;
            this.current.Policy_Money = 0;
            this.current.Policy_Delay = 0;
            this.current.Policy_HidePrice = 0;
            this.current.Policy_IsEvery = "";
            this.current.Policy_Quality = "";
            this.current.Policy_Type = "";
            this.current.Policy_Brief = "";
            this.current.Policy_Term = "";
            delete this.current.Policy_Create
            delete this.current.Policy_UpdateTime
        },
        sureAdd() {
            // console.log("current add:", this.current)
            this.ipost(
                "/AdminTravel/PolicyTravel.ashx?type=insert", {
                model: JSON.stringify(this.current)
            },
                (res) => {
                    // console.log("添加成功 res:", res)
                    this.addPolicy = false
                    this.pullList()
                }
            );
        },
        sendDelete(itm) {
            this.ipost(
                "/AdminTravel/PolicyTravel.ashx?type=delete", {
                id: itm.Policy_ID,
            },
                (res) => {
                    this.pullList()
                }
            );
        },

        //空为全部, 1：境内（普通）, 2：境外（普通）, 3：境内（高端）, 4：境外（高端）
        pullList() {
            this.ipost(
                "/AdminTravel/PolicyTravel.ashx?type=select", {
                keyword: this.keyword,
                pageindex: this.pageindex,
                pagesize: this.pagesize,
                quality: this.quality
            },
                (res) => {
                    this.ProductList = res.list
                    this.total = res.count
                    // console.log("res:", res)
                }
            );
        },
        ProductType(num) {
            let texts = ""
            switch (Number(num)) {
                case 1:
                    texts = '境内（普通）';
                    break;
                case 2:
                    texts = '境外（普通）';
                    break;
                case 3:
                    texts = '境内（高端）';
                    break;
                case 4:
                    texts = '境外（高端）';
                    break;
                case 5:
                    texts = '境内（普通含延误险）';
                    break;
                case 6:
                    texts = '境外（普通含延误险）';
                    break;
                case 7:
                    texts = '境内（高端含延误险）';
                    break;
                case 8:
                    texts = '境外（高端含延误险）';
                    break;
                default:
                    texts = '空';
                    break
            }
            return texts
        },
        setPageIndx(pgindx) {
            this.pageindex = pgindx;
            this.pullList();
        },
        searchKeyWord() {
            this.pageindex = 1;
            this.pullList();
        },
    }

}
</script>

<style scoped>
.el-table table {
    width: 100%;
}

.cell button,
.dialog-footer .el-button {
    margin: 0 8px;
}

.search-wrp .el-input {
    width: 60%;
    margin-right: 10px;
}

.el-collapse-item .el-row {
    padding: 6px 12px;
}

.el-pagination {
    margin: 16px auto;
    text-align: center;
}

.el-dialog .el-select {
    width: 100%;
}

.btm-btns {
    margin-top: 25px;
    text-align: center;
}

.el-col {
    padding: 5px;
}

.el-col label {
    line-height: 2.5;
}

.dataFilterWrap .el-row .el-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dataFilterWrap .el-row .el-col label {
    display: block;
    width: 80px;
    padding-right: 2px;
}

.dataFilterWrap button {
    margin-left: 10px;
}

.search-wrp-top .el-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-details .el-input,
.order-details .el-select {
    width: 90%;
}

.order-details button {
    display: block;
    height: 40px;
    width: 90%;
}

.order-details label {
    display: block;
    line-height: 3;
}

.details-btn {
    text-align: center;
    padding-top: 20px;
}
</style>
